@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "brandon-normal";
  src: local("brandon-normal"),
    url(./assets/brandon-grotesque-light-58a8a4b38001d.otf) format("truetype");
}
@font-face {
  font-family: "brandon-bold";
  src: local("brandon-bold"),
    url(./assets/BrandonGrotesque-Bold.otf) format("truetype");
}
@font-face {
  font-family: "brandon-medium";
  src: local("brandon-medium"),
    url(./assets/Brandon_med.otf) format("truetype");
}
